import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";

function MailTo(props) {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard text={props.mailto} onCopy={() => setCopied(true)}>
      <button type="button" class="btn btn-lg btn-primary">
        {copied ? "Email copied" : props.label}
      </button>
    </CopyToClipboard>
  );
}

export default MailTo;
