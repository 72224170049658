import { motion } from "framer-motion";
import { Row, Col, Container } from "react-bootstrap";
import PersonalPicture from "../../components/PersonalPicture/PersonalPicture";

function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container className="">
        <h2 style={{ margin: "2rem" }}>
          If you are reading this, then that most likely means that I am
          interested in working for your team/company. So, allow me to tell you
          a bit about myself.
        </h2>
        <Row xs={1} sm={1} md={2} lg={2} xl={2}>
          <Col xl={4} lg={4} md={4}>
            <PersonalPicture />
          </Col>
          <Col xl={8} lg={8} md={8}>
            <p className="fs-5" style={{ textAlign: "left" }}>
              I pride myself on being an innovator. I won't settle for just a
              'working' solution. One of my favorite things about developing is
              hunting down the BEST way to accomplish a task.
            </p>
            <p className="fs-5" style={{ textAlign: "left" }}>
              I have a strong foundation in computer science fundamentals.
              Whether it's Data structures, algorithms, or simply testing and
              debugging, I know it and live by it.
            </p>
            <p className="fs-5" style={{ textAlign: "left" }}>
              I love to learn. About anything really. But in the scope of being
              a Software Developer, I am always eager to learn/adopt new
              technologies.{" "}
              <strong>
                If I don't already know it, then I want to learn it. If I
                ALREADY know it, then I want to learn to know it even better.
              </strong>
            </p>
            <p className="fs-5" style={{ textAlign: "left" }}>
              In my free time, I enjoy playing video games with my friends. When
              I'm not working or studying, I am usually playing some kind of
              video game. My favorite games are mostly various kinds of RPGs.
              When it comes to general media, I am a huge fan of the horror
              genre. Horror movies, games, shows, or even books. I love the
              feeling of being scared.
            </p>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}

export default About;
