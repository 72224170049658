import { Container } from "react-bootstrap";
import { FaGithub, FaLinkedin, FaFacebookSquare } from "react-icons/fa";

function HomeLinks(props) {
  function handleLIClick() {
    window.open("https://www.linkedin.com/in/harry-troast-76b45b1a0");
  }
  function handleGHClick() {
    //window.open("https://github.com/harrytroast");
  }
  function handleFBClick() {
    window.open("https://www.facebook.com/harrison.troast.73/");
  }

  return (
    <Container style={{ marginTop: `${props.margin}` }}>
      <FaLinkedin
        style={{ margin: "0.5rem", cursor: "pointer" }}
        onClick={handleLIClick}
      />
      <FaGithub
        style={{ margin: "0.5rem", cursor: "pointer" }}
        onClick={handleGHClick}
      />
      <FaFacebookSquare
        style={{ margin: "0.5rem", cursor: "pointer" }}
        onClick={handleFBClick}
      />
    </Container>
  );
}

export default HomeLinks;
