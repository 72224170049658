import { motion } from "framer-motion";
import HomeLinks from "../../components/HomeLinks/HomeLinks";
import MailTo from "../../components/MailTo/MailTo";

function Contact() {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 style={{ marginTop: "10vh" }}>
        If you think I may be a good fit for you, then please send me an email
        and I will provide my formal CV and phone number. Please also feel free
        to send me job listings as well. Looking forward to hearing from you!
      </h2>
      <MailTo label="harrytroast@gmail.com" mailto="harrytroast@gmail.com" />
      <HomeLinks margin="20vh" />
    </motion.div>
  );
}

export default Contact;
