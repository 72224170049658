import { motion } from "framer-motion";
import { Container, Row } from "react-bootstrap";
import Project from "../../components/Project/Project";

import "./Projects.css";

const projects = [
  {
    projectTitle: "This website",
    techStacks: ["react"],
    imgurl:
      "https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_4x3.jpg",
    liveDemo: "",
    githubRepo: "",
    key: "1",
  },
  {
    projectTitle: "Placeholder Proj",
    techStacks: ["python", "react"],
    imgurl:
      "https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_4x3.jpg",
    liveDemo: "",
    githubRepo: "",
    key: "2",
  },
  {
    projectTitle: "Placeholder Proj 2",
    techStacks: ["react"],
    imgurl:
      "https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_4x3.jpg",
    liveDemo: "",
    githubRepo: "",
    key: "3",
  },
  {
    projectTitle: "4",
    techStacks: ["react"],
    imgurl:
      "https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_4x3.jpg",
    liveDemo: "",
    githubRepo: "",
    key: "4",
  },
];

function Projects() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container className="pContainer">
        <Row xs={1} md={2} lg={3} className="g-4">
          {projects.map((entry, idx) => (
            <Project
              projectTitle={entry.projectTitle}
              techStacks={entry.techStacks}
              imgurl={entry.imgurl}
              key={entry.key}
            />
          ))}
        </Row>
      </Container>
    </motion.div>
  );
}

export default Projects;
