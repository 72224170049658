import "./Timeline.css";

function TimelineAcademic() {
  const date = new Date();

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function buildDate(date) {
    date.setHours(0, 0, 0, 0);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("-");
  }

  return (
    <div
      className="container"
      style={{ marginTop: "1rem", marginBottom: "1rem" }}
    >
      <h1 className="text-center display-6">Higher Education</h1>
      <h4 className="text-center">
        {`As of ${buildDate(
          date
        )}, I am Currently attending Stevens Institute of Technology, aiming for my MS in
        CS`}
      </h4>
      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline7">
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-globe" />
              </div>
              <span className="year">Sept 2021</span>
              <div className="timeline-content">
                <h5 className="title">
                  Enrolled at Stevens Institute of Technology's Master of
                  Science Program
                </h5>
                <p className="description">
                  Going for my Master of Science in Computer Science.
                </p>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-globe" />
              </div>
              <span className="year">Dec 2020</span>
              <div className="timeline-content">
                <h5 className="title">
                  Graduated from Rutgers University with my Bachelor's of
                  Science in Computer Science
                </h5>
                <p className="description">
                  I began working full time as a Software Developer the
                  following January
                </p>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-rocket" />
              </div>
              <span className="year">Jan 2017</span>
              <div className="timeline-content">
                <h5 className="title">Transferred to Rutgers University</h5>
                <p className="description">
                  Transfer was motivated by my desire for a better Computer
                  Science program.
                </p>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-briefcase" />
              </div>
              <span className="year">Sept 2015</span>
              <div className="timeline-content">
                <h5 className="title">
                  Enrolled at William Paterson University's Computer Science
                  Program
                </h5>
                <p className="description">
                  My coursework here was mostly focused on general courses.
                </p>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-mobile" />
              </div>
              <span className="year">June 2015</span>
              <div className="timeline-content">
                <h5 className="title">
                  Graduated from Pequannock Township Highscool
                </h5>
                <p className="description">
                  By this time I knew exactly what I wanted to pursue
                  professionally(Computer Science) and I was incredibly
                  motivated to do so.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimelineAcademic;
