import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

//Pages
import About from "../pages/About/About";
import Education from "../pages/Education/Education";
import Experience from "../pages/Experience/Experience";
import Home from "../pages/Home/Home";
import Projects from "../pages/Projects/Projects";
import Skills from "../pages/Skills/Skills";
import Contact from "../pages/Contact/Contact";

function RouteContent() {
  return (
    <div>
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/education" element={<Education />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default RouteContent;
