import { Figure } from "react-bootstrap";

import "../../Skills.css";

function Skill(props) {
  const uri = props.url;
  return (
    <Figure className="skill">
      <Figure.Image width={100} height={100} alt="100x100" src={uri} />
      <Figure.Caption>{props.caption}</Figure.Caption>
    </Figure>
  );
}

export default Skill;
