import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import TimelineAcademic from "../../components/Timeline/TimelineAcademic";

function Education() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container>
        <TimelineAcademic />
      </Container>
    </motion.div>
  );
}

export default Education;
