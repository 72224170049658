import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import HomeLinks from "../../components/HomeLinks/HomeLinks";
import RotatingIma from "../../components/RotatingIma/RotatingIma";

function Home() {
  return (
    <Container className="position-relative ">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="top-50 start-50">
          <h1 className=" display-1" style={{ marginTop: "25vh" }}>
            Hi, I'm Harry Troast
          </h1>
          <RotatingIma />
          <HomeLinks margin={"7vw"} />
        </div>
      </motion.div>
    </Container>
  );
}

export default Home;
