function TechStackTag(props) {
  const tagData = {
    python: {
      name: "Python",
      color: "#323aa8",
      url: "https://www.python.org/",
    },
    react: {
      name: "React",
      color: "#3256a8",
      url: "https://reactjs.org/",
    },
  };

  return (
    <span
      class="badge rounded-pill"
      style={{ backgroundColor: tagData[props.stack].color }}
    >
      {tagData[props.stack].name}
    </span>
  );
}

export default TechStackTag;
