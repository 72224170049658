import { motion } from "framer-motion";

function Ima(props) {
  return (
    <motion.div
      key={props.cap}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, ease: "easeOut" }}
      exit={{ opacity: 0 }}
    >
      <h1 className=" display-4">{props.cap}</h1>
    </motion.div>
  );
}

export default Ima;
