import { useEffect, useState } from "react";
import Ima from "./Ima";

const whoAmI = ["Developer", "Innovator", "Teammate", "Gamer"];
let idx = 0;

function RotatingIma(props) {
  const [currentCaption, setCurrentCaption] = useState("Developer");

  useEffect(() => {
    const interval = setInterval(() => {
      updateCaption();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  function updateCaption() {
    if (idx === whoAmI.length - 1) {
      idx = 0;
    } else {
      idx++;
    }
    setCurrentCaption(whoAmI[idx]);
  }

  return <Ima cap={currentCaption} />;
}

export default RotatingIma;
