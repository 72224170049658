import { BrowserRouter } from "react-router-dom";
import { IconContext } from "react-icons";

import "./App.css";
import "./bootstrap.min.css";

//Nav
import AnchorNav from "./components/Navbar/AnchorNav";

//Routes
import RouteContent from "./shared/RouteContent";

function App() {
  return (
    <div className="App">
      <IconContext.Provider value={{ size: "3em" }}>
        <BrowserRouter>
          <AnchorNav />
          <RouteContent />
        </BrowserRouter>
      </IconContext.Provider>
    </div>
  );
}

export default App;
