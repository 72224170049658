import { Container, Image } from "react-bootstrap";

function PersonalPicture() {
  return (
    <Container>
      <Image fluid src={require("./picture.jpg")} />
    </Container>
  );
}

export default PersonalPicture;
