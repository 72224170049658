import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import Skill from "./components/Skill/Skill";

import "./Skills.css";

//Langs logos
import pythonlogo from "./logos/Python.png";
import jslogo from "./logos/javascript.jpeg";
import javalogo from "./logos/Java.png";
import gologo from "./logos/golang.png";

//Framework logos
import reactlogo from "./logos/react.png";
import nextlogo from "./logos/nextjs.png";
import expresslogo from "./logos/express.jpg";
import springlogo from "./logos/spring.jpg";
import djangologo from "./logos/django.png";

//DBMS logos
import mongologo from "./logos/mongodb.png";
import postgreslogo from "./logos/postgres.png";
import redislogo from "./logos/redis.png";
import oraclelogo from "./logos/oraclesql.png";

//Cloud logos
import ec2logo from "./logos/ec2.png";
import lightsaillogo from "./logos/lightsail.png";
import s3logo from "./logos/s3.png";
import cloudflarelogo from "./logos/cloudflare.png";
import nginxlogo from "./logos/nginx.png";

//testing logos
import seleniumlogo from "./logos/selenium.jpg";
import postmanlogo from "./logos/postman.png";

//tools logos
import vscodelogo from "./logos/vscode.png";
import eclipselogo from "./logos/eclipse.png";
import dockerlogo from "./logos/docker.png";
import ubuntulogo from "./logos/ubuntu.jpg";

const langs = [
  {
    cap: "Python",
    imgurl: pythonlogo,
    stackurl: "https://www.python.org/",
  },
  {
    cap: "JavaScript",
    imgurl: jslogo,
    stackurl: "https://www.javascript.com/",
  },
  {
    cap: "Java",
    imgurl: javalogo,
    stackurl: "https://www.java.com/en/",
  },
  {
    cap: "Golang",
    imgurl: gologo,
    stackurl: "https://go.dev/",
  },
];

const frameworks = [
  {
    cap: "React.js",
    imgurl: reactlogo,
    stackurl: "https://reactjs.org/",
  },
  {
    cap: "Next.js",
    imgurl: nextlogo,
    stackurl: "https://nextjs.org/",
  },
  {
    cap: "Express.js",
    imgurl: expresslogo,
    stackurl: "https://expressjs.com/",
  },
  {
    cap: "Spring",
    imgurl: springlogo,
    stackurl: "https://spring.io/",
  },
  {
    cap: "Django",
    imgurl: djangologo,
    stackurl: "https://www.djangoproject.com/",
  },
];

const dbms = [
  {
    cap: "MongoDB",
    imgurl: mongologo,
    stackurl: "https://www.mongodb.com/",
  },
  {
    cap: "PostgreSQL",
    imgurl: postgreslogo,
    stackurl: "https://www.postgresql.org/",
  },
  {
    cap: "Redis",
    imgurl: redislogo,
    stackurl: "https://redis.io/",
  },
  {
    cap: "Oracle",
    imgurl: oraclelogo,
    stackurl:
      "https://www.oracle.com/database/technologies/appdev/sqldeveloper-landing.html",
  },
];

const cloud = [
  {
    cap: "AWS EC2",
    imgurl: ec2logo,
  },
  {
    cap: "AWS Lightsail",
    imgurl: lightsaillogo,
  },
  {
    cap: "AWS S3",
    imgurl: s3logo,
  },
  {
    cap: "Cloudflare",
    imgurl: cloudflarelogo,
  },
  {
    cap: "NGINX",
    imgurl: nginxlogo,
  },
];

const testing = [
  {
    cap: "Selenium",
    imgurl: seleniumlogo,
  },
  {
    cap: "Postman",
    imgurl: postmanlogo,
  },
];

const tools = [
  {
    cap: "VSCode",
    imgurl: vscodelogo,
  },
  {
    cap: "Eclipse",
    imgurl: eclipselogo,
  },
  {
    cap: "Docker",
    imgurl: dockerlogo,
  },
  {
    cap: "Ubuntu",
    imgurl: ubuntulogo,
  },
];

function createSkill(header, data) {
  return (
    <div>
      <h3>{header}</h3>
      {data.map((entry) => (
        <Skill caption={entry.cap} url={entry.imgurl} />
      ))}
    </div>
  );
}

function Skills() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container className="pContainer">
        {createSkill("Languages", langs)}
        {createSkill("Libraries/Frameworks", frameworks)}
        {createSkill("Database Systems", dbms)}
        {createSkill("Cloud/Server utilities", cloud)}
        {createSkill("Testing tools", testing)}
        {createSkill("Other tools", tools)}
      </Container>
    </motion.div>
  );
}

export default Skills;
