import { Card, Col } from "react-bootstrap";
import TechStackTag from "../TechStackTag/TechStackTag";

function Project(props) {
  return (
    <Col>
      <Card>
        <Card.Img variant="top" src={props.imgurl} />
        <Card.Body>
          <Card.Title>{props.projectTitle}</Card.Title>
          <Card.Text className="fs-4">
            {props.techStacks.map((stack) => (
              <TechStackTag stack={stack} />
            ))}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Project;
