import "./Timeline.css";

function TimelineProfessional() {
  const date = new Date();

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function buildDate(date) {
    date.setHours(0, 0, 0, 0);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("-");
  }

  return (
    <div
      className="container"
      style={{ marginTop: "1rem", marginBottom: "1rem" }}
    >
      <h1 className="text-center display-6">Professional Experience</h1>
      <h4 className="text-center">
        {`As of ${buildDate(
          date
        )}, I am Currently working at Fiserv as a software developer`}
      </h4>
      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline7">
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-globe" />
              </div>
              <span className="year">July 2022</span>
              <div className="timeline-content">
                <h5 className="title">
                  Fiserv - Software Developer (Risk Portfolio)
                </h5>
                <p className="description">
                  Hybrid work environment, mostly remote with occasional office
                  days. Responsibilities included building and maintaing various
                  tools/services that Fiserv offers to their clients. Tech
                  stacks consists of Java, Apache Struts/JSP, Apache Kafka,
                  Oracle SQL. Deployed with JBOSS and utilizes Azure DevOps for
                  CI/CD.
                </p>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-globe" />
              </div>
              <span className="year">Jan 2021</span>
              <div className="timeline-content">
                <h5 className="title">
                  Fiserv - Entry Level Software Developer (Network Portfolio)
                </h5>
                <p className="description">
                  Hybrid work environment, mostly remote with occasional office
                  days. Responsibilities included building and maintaining tools
                  for Fiserv's STAR financial network, primarily their
                  Participant Management web application. Was a member of an
                  Agile Development Team, with my role as a developer. Tech
                  stack consisted of Java, Springboot, Apache Struts/JSP, Oracle
                  SQL.
                </p>
              </div>
            </div>
            <div className="timeline">
              <div className="timeline-icon">
                <i className="fa fa-globe" />
              </div>
              <span className="year">June 2020</span>
              <div className="timeline-content">
                <h5 className="title">Fiserv - Software Development Intern</h5>
                <p className="description">
                  This internship occured on the summer just prior to my final
                  semester of my undergrad. My tasks consisted of getting
                  acclimated to Fiserv's ecosystem, learning about the Fintech
                  world, and finally implementing additional functionality to an
                  existing internal application(written in C).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimelineProfessional;
