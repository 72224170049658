import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import TimelineProfessional from "../../components/Timeline/TimelineProfessional";

function Experience() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container>
        <TimelineProfessional />
      </Container>
    </motion.div>
  );
}

export default Experience;
